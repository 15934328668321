/**
 * サンプルクラス
 * @constructor
 */
class ZoomBox {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    $('.m-zoomBox__inner').hover(function () {
      $(this).find('.m-zoomBox__lineLink').addClass('is-active');
      $(this).find('.m-zoomBox__img').addClass('is-active');
    }, function () {
      $(this).find('.m-zoomBox__lineLink').removeClass('is-active');
      $(this).find('.m-zoomBox__img').removeClass('is-active');
    })

  }
};

export default ZoomBox;
