import Slick from "slick-carousel";
/**
 * サンプルクラス
 * @constructor
 */
class MainSlide01 {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

  }
};

export default MainSlide01;
