/**
 * サンプルクラス
 * @constructor
 */
class ListInner {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    // 製品一覧ページの余白調整用
    if($('.p-prodList__listInner').length){

      let listInner = $('.p-prodList__listInner').width();
      let itemWidth = $('.m-prodItem').width()+ 24;
      let amari = listInner % itemWidth;

      window.addEventListener('resize',()=>{

        if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0) {
          // スマートフォン向けの記述
          } else if (navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0) {
            // タブレット向けの記述
          } else {
            // PC向けの記述
            if( amari >= 34 ){
              $('.p-prodList__list').css('padding-right','0');
            }
          }
      })

    }

  }
};

export default ListInner;
