import Swiper from "swiper";

/**
 * コモンクラス
 * @constructor
 */
 class LpSlide {
    constructor() {

    }
    /**
     * コモン
     */
     init() {
        var mySwiper1 = new Swiper('.slide_sllfi_blk', {
          loop: true,
          effect: 'fade',
          observer: true,
          observeParents: true,
            fadeEffect: {
                crossFade: true
            },
          navigation: {
            nextEl: '.allfi_plus_blk_next',
            prevEl: '.allfi_plus_blk_prev',
          }
        });

        var mySwiper2 = new Swiper('.slide_sllfi_wt', {
          loop: true,
          effect: 'fade',
          observer: true,
          observeParents: true,
            fadeEffect: {
                crossFade: true
            },
          navigation: {
            nextEl: '.allfi_plus_wt_next',
            prevEl: '.allfi_plus_wt_prev',
          }
        });

        var mySwiper3 = new Swiper('.slide_noar_blk', {
          loop: true,
          effect: 'fade',
          observer: true,
          observeParents: true,
            fadeEffect: {
                crossFade: true
            },
          navigation: {
            nextEl: '.noar_plus_blk_next',
            prevEl: '.noar_plus_blk_prev',
          }
        });

        var mySwiper4 = new Swiper('.slide_noar_wt', {
          loop: true,
          effect: 'fade',
          observer: true,
          observeParents: true,
            fadeEffect: {
                crossFade: true
            },
          navigation: {
            nextEl: '.noar_plus_wt_next',
            prevEl: '.noar_plus_wt_prev',
          }
        });

      }
    };

  export default LpSlide;