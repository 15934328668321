/**
 * サンプルクラス
 * @constructor
 */
 class LpHeader {
    constructor() {
  
    }
    /**
     * サンプル
     */
    init() {

        const agent = window.navigator.userAgent.toLowerCase()

        if (agent.indexOf("msie") != -1 || agent.indexOf("trident") != -1) {
            $('.js-lpLogo01').hide();
            $('.js-lpLogo02').show();
          } else {
              $('.js-lpLogo02').hide();
              $('.js-lpLogo01').show();
          }

    }
  };
  export default LpHeader;