/**
 * サンプルクラス
 * @constructor
 */
class ImageBox {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    $('.m-imageBox__boxCont').hover(function () {
      $(this).prev('.m-imageBox__bg').addClass('is-active');
      $(this).find('.p-enjoymentLineLink').addClass('is-active');
    }, function () {
      $(this).prev('.m-imageBox__bg').removeClass('is-active');
      $(this).find('.p-enjoymentLineLink').removeClass('is-active');
    })

  }
};

export default ImageBox;
