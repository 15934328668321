import Slick from "slick-carousel";
/**
 * サンプルクラス
 * @constructor
 */
class SlickSetting {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    // 製品スライド1（色切り替えあり）
    var slide_main = $(".slide").slick({
      asNavFor: ".slide-navigation",
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
    });

    var slide_sub = $(".slide-navigation").slick({
      asNavFor: ".slide",
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      speed: 400,
      focusOnSelect: true,
    });
    var open_window_Width = $(window).width();
    $(window).resize(function() {
      var width = $(window).width();
      if (open_window_Width != width) {
      slide_main.slick("setPosition");
      slide_sub.slick("setPosition");
      }
    });

  var $slickElement = $('.slide-navigation');
  $('.slide-navigation').on('afterChange', function (event, slick, currentSlide) {
      var currentindex = (currentSlide ? currentSlide : 0) +1;
      var totalSlide = (slick.slideCount);

      if (totalSlide >5){
        if ( totalSlide - currentindex === 0){
          $('.slick-next.slick-arrow.slick-disabled').css('opacity','0.25');
        } else {
            $('.slick-next.slick-arrow.slick-disabled').css('opacity','0.75');
            $('.slick-next.slick-arrow').css('opacity','0.75');
        };
      };
  });

  $('.mainColor2,.mainColor3,.mainColor4,.mainColor5').hide();

  $('.mainColorBtn').on('click',(e)=>{
      let colortaget = $(e.currentTarget).attr('data-color');
      let target = $(e.currentTarget);
      let hideTarget01 = $('.m-mainSlide').find('.slideImage,.thumbnailImage');
      let hideTarget02 = $('.linkBtn');
      $(hideTarget01).hide();
      $(hideTarget02).hide();
      $('.'+colortaget).show();
      $('.mainColorBtn').removeClass('select');
      target.addClass('select');

      // 色切り替えたら1枚目を表示
      $('.slide').slick('slickGoTo', 0, true);
  });
  // 製品スライド1（色切り替えあり）ここまで


  // 商品情報スライド
  var slide_main = $(".infoSlide").slick({
    asNavFor: ".infoSlide-navigation",
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
  });
  var slide_sub = $(".infoSlide-navigation").slick({
    asNavFor: ".infoSlide",
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 400,
    focusOnSelect: true,
  });
  var open_window_Width = $(window).width();
  $(window).resize(function() {
    var width = $(window).width();
    if (open_window_Width != width) {
    slide_main.slick("setPosition");
    slide_sub.slick("setPosition");
    }
  });
// 商品情報スライドここまで

// フォトギャラリースライド（背景濃い色パターン：矢印 #ffffff）
$('.p-prodPhotoGallery__inner').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    arrows: true,
    speed: 400,

    responsive: [{
      breakpoint: 768, // ブレイクポイントを指定
      settings: {
        slidesToShow: 1,
      },
    },
    {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      centerMode: false,
    },
    },
    ]
  });
// フォトギャラリースライドここまで

// フォトギャラリースライド（背景薄い色パターン：矢印 #444444）
$('.p-prodPhotoGallery2__inner').slick({
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  dots: false,
  arrows: true,
  speed: 400,

  responsive: [{
    breakpoint: 768, // ブレイクポイントを指定
    settings: {
      slidesToShow: 1,
    },
  },
  {
  breakpoint: 480,
  settings: {
    slidesToShow: 1,
    centerMode: false,
  },
  },
  ]
});
// フォトギャラリースライドここまで


// トップ コラム
$('.p-topColumnSlider').slick({
  mobileFirst: true,
  slidesToShow: 1,
  infinite: false,
  dots: false,
  arrows: false,
  centerMode: true,
  centerPadding: "10%",

  responsive: [{
    breakpoint: 750, // ブレイクポイントを指定(750px基本)
    settings: 'unslick',
  }]
});

$(window).on('resize orientationchange', function() {
  $('.p-topColumnSlider').slick('resize');
});

// トップ コラムここまで


// トップ About
$('.p-topAboutSlider').slick({
  mobileFirst: true,
  slidesToShow: 1,
  infinite: false,
  dots: false,
  arrows: false,
  centerMode: true,
  centerPadding: "10%",

  responsive: [{
    breakpoint: 750, // ブレイクポイントを指定(750px基本)
    settings: 'unslick',
  }]
});

$(window).on('resize orientationchange', function() {
  $('.p-topAboutSlider').slick('resize');
});
// トップ Aboutここまで

// トップ（動画・静止画）スライド
$('.p-topKv__slider').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 10000,
  autoplay: true,
  dots: true,
  arrows: false,
  pauseOnFocus: false, //フォーカスで一時停止
  pauseOnHover: false, //マウスホバーで一時停止

  responsive: [{
    // breakpoint: 768, // ブレイクポイントを指定
    breakpoint: 750, // ブレイクポイントを指定
    settings: {
      slidesToShow: 1,
    },
  },
  ]
});
// トップ（動画・静止画）スライドここまで

  }
};

export default SlickSetting;
