
class Acc {
  constructor() {

  }

  init() {

    $('.js-open').removeClass('.is-open');

    $('.js-open').on('click', (e) => {
      this.openInner(e.currentTarget);
    });

  }

  openInner(el) {
    $(el).toggleClass('is-open');
    $(el).next('.js-wrap').slideToggle();
    return false;
  }

};

export default Acc;
