/**
 * サンプルクラス
 * @constructor
 */
  class Scroll {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    if (matchMedia( "(max-width: 768px)" ).matches) {
      // SP
      let searchBtn = $('.m-searchBtn');

      if(searchBtn.length){
        // ITEM SEARCHボタンがあるページではSPでのスクロール時に被らないようにする
        $(function(){
          let headerHeight = 260;
          $('a[href^="#"]' + 'a:not(a[href^="#top"])').on('click',function(){
              let href= $(this).attr("href");
              let target = $(href == "#" || href == "" ? 'html' : href);
              let position = target.offset().top - headerHeight;
              $("html, body").animate({scrollTop:position}, 1000, "swing");
              return false;
          });
        });
      } else {
        // ITEM SEARCHボタンがないページ
        $(function(){
          let headerHeight = 160;
          $('a[href^="#"]' + 'a:not(a[href^="#top"])').on('click',function(){
              let href= $(this).attr("href");
              let target = $(href == "#" || href == "" ? 'html' : href);
              let position = target.offset().top - headerHeight;
              $("html, body").animate({scrollTop:position}, 1000, "swing");
              return false;
            });
          });
        }
      } else {
        // PC
        $(function(){
            let headerHeight = $('.l-headerPc').height();
            $('a[href^="#"]'+ 'a:not(a[href^="#top"])').on('click',function(){
                let href= $(this).attr("href");
                let target = $(href == "#" || href == "" ? 'html' : href);
                let position = target.offset().top - headerHeight;
                $("html, body").animate({scrollTop:position}, 1000, "swing");
                return false;
            });
        });
      }
    }
};

export default Scroll;
