/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
// import App from './vue/App.js';

/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
// import SmoothScroll from 'smooth-scroll';

/**
 * import modules
 */
import Sample from './modules/sample';
import Common from './modules/common';
import Acc from './modules/acc';
import ModaalSetting from './modules/modaalSetting';
import Header from './modules/header';
import Hamburger from './modules/hamburger';
import ImageBox from './modules/imageBox';
import LineLink from './modules/lineLink';
import LinkBox from './modules/linkBox';
import Luxy from './modules/luxy';
import ListInner from './modules/listInner';
import ZoomBox from './modules/zoomBox';
import ZoomSlide from './modules/zoomSlide';
import Active from './modules/active';
import SwiperSetting from './modules/swiperSetting';
import MainSlide01 from './modules/mainSlide01';
import SlickSetting from './modules/slickSetting';
import SwitchingImg from './modules/switchingImg';
import SearchMenu from './modules/serachMenu';
import SearchMenuAnchor from './modules/serachMenuAnchor';
import Scroll from './modules/scroll';
import TopAcc from './modules/topAcc';
import Expansion from './modules/expansion';
import LpSlide from './modules/lpSlide';
import LpHeader from './modules/lpHeader';
import TopScrollFadeIn from './modules/TopScrollFadeIn';

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();

    const sample = new Sample();
    sample.init();

    const common = new Common();
    common.init();

    const acc = new Acc();
    acc.init();

    const modaalSetting = new ModaalSetting();
    modaalSetting.init();

    const header = new Header();
    header.init();

    const hamburger = new Hamburger();
    hamburger.init();

    const imageBox = new ImageBox();
    imageBox.init();

    const lineLink = new LineLink();
    lineLink.init();

    const linkBox = new LinkBox();
    linkBox.init();

    const luxy = new Luxy();
    luxy.init();

    const listInner = new ListInner();
    listInner.init();
    
    const zoomBox = new ZoomBox();
    zoomBox.init();

    const zoomSlide = new ZoomSlide();
    zoomSlide.init();

    const active = new Active();
    active.init();

    const mainSlide01 = new MainSlide01();
    mainSlide01.init();

    const swiperSetting = new SwiperSetting();
    swiperSetting.init();

    const slickSetting = new SlickSetting();
    slickSetting.init();

    const switchingImg = new SwitchingImg();
    switchingImg.init();

    const searchMenu = new SearchMenu();
    searchMenu.init();

    const searchMenuAnchor = new SearchMenuAnchor();
    searchMenuAnchor.init();

    const scroll = new Scroll();
    scroll.init();

    const topAcc = new TopAcc();
    topAcc.init();

    const expansion = new Expansion();
    expansion.init();

    const lpSlide = new LpSlide();
    lpSlide.init();
    
    const lpHeader = new LpHeader();
    lpHeader.init();

    const topScrollFadeIn = new TopScrollFadeIn();
    topScrollFadeIn.init();
  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {


    this.init_deSVG('.js-desvg');

    this.init_objectFitImages('.object-fit');

    // this.init_smoothScroll();
  }



  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  // init_smoothScroll() {
  //   /**
  //    * ターゲットとなる要素
  //    * @type {Element}
  //    */
  //   const targetElement = 'a[href*="#"]';

  //   /**
  //    * オプション
  //    * @type {object}
  //    */
  //   const option = {
  //     speed: 1000,
  //     easing: 'easeInOutCubic',
  //     speedAsDuration: true,
  //     header: '.headerHeight'
  //   }

  //   new SmoothScroll(targetElement, option);
  // }
}

export default new Main();
