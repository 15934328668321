/**
 * サンプルクラス
 * @constructor
 */
class TopAcc {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

      if (window.matchMedia( "(max-width: 768px)" ).matches) {

        $('.m-linkBox__slideTtlCont').on('click', function () {
          $(this).next('.m-linkBox__slideBoxCont').slideToggle(500);
          $(this).find('.slideIcon').toggleClass('is-active');
        })

      }

  }
};

export default TopAcc;
