/**
 * サンプルクラス
 * @constructor
 */
 class LineLink {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {
    
    $('.m-lineLink').hover(function () {
      $(this).find('.more__line').addClass('is-active'),
      $(this).find('.more__txt').addClass('is-active');
    }, function () {
      $(this).find('.more__line').removeClass('is-active'),
      $(this).find('.more__txt').removeClass('is-active');
    })
    
  }
};

export default LineLink;
