/**
 * サンプルクラス
 * @constructor
 */
class Hamburger {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

      if (window.matchMedia( "(max-width: 768px)" ).matches) {

        // ハンバーガーメニューボタンクリックしたら
        $('.ham__button').on('click', function () {

          // メニューを閉じるときの動作
          if ($(this).hasClass('btn-active')) {// メニューが空いていたら閉じる
            $(this).removeClass('btn-active');
            $('body').removeClass('body-fixed');

          } else {

            // メニューを開くときの動作
            $(this).addClass('btn-active');
            $('body').addClass('body-fixed');
          }

          $('.ham__bg').fadeToggle();
          $('.ham__navWrapper').toggleClass('is-open');

        })

        // ハンバーガーメニューの背景をクリックしたら閉じる
        $('.ham__bg').click(function () {
          $(this).fadeOut();
          $('.ham__button').removeClass('btn-active');
          $('.ham__navWrapper').removeClass('is-open');
          $('body').removeClass('body-fixed');
        });
      }

    // 共通の処理

    // メニュー閉じたら中身も閉じる
    // $('.ham__button,.ham__bg').on('click', function () {
    //   if(!$(this).hasClass('btn-active')){
    //     $('.ham__secondList').slideUp(500);
    //     $('.ham__thirdList').slideUp(500);
    //     $(".touch-btn").removeClass('is-active');
    //   }
    // })

    // 製品情報閉じたら中身も閉じる
    // $('.ham__slide01').on('click', function () {
    //   $('.ham__thirdList').slideUp();
    //   $('.ham__fourthList').slideUp();
    //   $(".ham__slide02").children('.touch-btn').removeClass('is-active');
    //   $(".ham__slide03").next('.touch-btn').removeClass('is-active');
    // })

    // メニュー内のアコーディオンの制御
    // $(".ham__slide01").on('click', function () {
    //   $(this).children('.touch-btn').toggleClass('is-active');
    //   $(this).next().slideToggle(500);
    // });

    // メニュー内のアコーディオンの制御(ブランドの階層)
    $(".ham__slide02").on('click', function () {

      let display = $(this).next('.ham__thirdList').css('display');

      if(display == 'none'){
        $(this).children('.touch-btn').addClass('is-active');
      }else if(display == 'block' ){
        $(this).children('.touch-btn').removeClass('is-active');
      }

      $(this).next('.ham__thirdList').slideToggle(500);
      $('.ham__slide02').not($(this)).next('.ham__thirdList').slideUp(500);
      $('.ham__slide02').not($(this)).children('.touch-btn').removeClass('is-active');

    });

    // メニュー内のアコーディオンの制御(ブランドの階層)
    $(".ham__slide03").on('click', function () {

      let display = $(this).next('.ham__fourthList').css('display');

      if(display == 'none'){
        $(this).children('.touch-btn').addClass('is-active');
      }else if(display == 'block' ){
        $(this).children('.touch-btn').removeClass('is-active');
      }

      $(this).next('.ham__fourthList').slideToggle(500);
      $('.ham__slide03').not($(this)).next('.ham__fourthList').slideUp(500);
      $('.ham__slide03').not($(this)).children('.touch-btn').removeClass('is-active');

    });

  }
};

export default Hamburger;
