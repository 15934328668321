/**
 * サンプルクラス
 * @constructor
 */
class LinkBox {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    // PCとSPで異なる挙動
    if (window.matchMedia( "(max-width: 768px)" ).matches) {

      // SP
      $('.m-linkBox__img').on('click',function(){
        $(this).siblings('.m-linkBox__slide').slideToggle(500);
        $(this).siblings('.m-linkBox__icon').toggleClass('is-active');
        }
      );

      } else {
        // PC
        $('.m-linkBox').hover(function () {
          $(this).find('.m-linkBox__slide').addClass('is-open');
          $(this).find('.m-linkBox__icon').addClass('is-active');
        }, function () {
          $(this).find('.m-linkBox__slide').removeClass('is-open');
          $(this).find('.m-linkBox__icon').removeClass('is-active');
        })
      }

      // m-linkBoxB (PC・SP共通でスライドダウン)
      $('.m-linkBoxB__img').on('click',function(){

        $('.m-linkBoxB__icon').removeClass('is-active');
        $('.m-linkBoxB__slideIcon').removeClass('is-active');
        $('.m-linkBoxB__slideItemCont').slideUp();
        
        if(($(this).siblings('.m-linkBoxB__slide').css('display') == 'none' )) {
          $(this).siblings('.m-linkBoxB__icon').addClass('is-active');
        } else {
          $(this).siblings('.m-linkBoxB__icon').removeClass('is-active');
        }
        $(this).siblings('.m-linkBoxB__slide').slideToggle();
        $('.m-linkBoxB__img').not($(this)).siblings('.m-linkBoxB__slide').slideUp();
          
      });

      // メリタの階層付きバージョン
      $('.m-linkBoxB__slideCategory').on('click',function(){

        $('.m-linkBoxB__slideIcon').removeClass('is-active');

        if(($(this).next('.m-linkBoxB__slideItemCont').css('display') == 'none' )) {
          $(this).children('.m-linkBoxB__slideIcon').addClass('is-active');
        } else {
          $(this).children('.m-linkBoxB__slideIcon').removeClass('is-active');
        }

        $(this).next('.m-linkBoxB__slideItemCont').slideToggle();
        $('.m-linkBoxB__slideCategory').not($(this)).next('.m-linkBoxB__slideItemCont').slideUp();
          
      });

  }
};

export default LinkBox;
