/**
 * サンプルクラス
 * @constructor
 */
class Expansion {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    // $('.p-icecoffeeVideo').hover(function () {
    //   $(this).next('.m-imageBox__bg').addClass('is-active');
    //   $(this).find('.p-enjoymentLineLink').addClass('is-active');
    // }, function () {
    //   $(this).next('.m-imageBox__bg').removeClass('is-active');
    //   $(this).find('.p-enjoymentLineLink').removeClass('is-active');
    // })

    let $trigger = $('.js-trigger');
    let $target = $('.js-target');

    $trigger.hover(function () {
      $(this).find($target).addClass('is-active');
      $(this).parent().find($target).addClass('is-active');
      $(this).prev().find('.p-icecoffeeVideo__thumb').addClass('is-active');
    }, function () {
        $(this).find($target).removeClass('is-active');
        $(this).parent().find($target).removeClass('is-active');
        $(this).prev().find('.p-icecoffeeVideo__thumb').removeClass('is-active');
    })



  }
};

export default Expansion;
