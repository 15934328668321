/**
 * サンプルクラス
 * @constructor
 */
class ZoomBox {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    $('.m-zoomSlide__item').hover(function () {
      $(this).toggleClass('is-active');
    })

  }
};

export default ZoomBox;
