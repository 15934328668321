/**
 * サンプルクラス
 * @constructor
 */
 class TopScrollFadeIn {
    constructor() {
  
    }
    /**
     * サンプル
     */
    init() {

      $(window).on('scroll', function () {
        $('.p-topjFadeIn').each(function(){
          var pos = $(this).offset().top;
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          if (scroll > pos - windowHeight + 250){
            $(this).addClass('p-topScroll');
          }
        });
      });

    }
  };
  export default TopScrollFadeIn;