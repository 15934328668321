/**
 * サンプルクラス
 * @constructor
 */
class Luxy {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    if($('#luxy').length){

      if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0) {
        // スマートフォン向けの記述
        } else if (navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0) {
          // タブレット向けの記述
        } else {
          // PC向けの記述
          luxy.init();
        }

    }

    
  }
};

export default Luxy;
