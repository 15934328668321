import Swiper from "swiper";
/**
 * サンプルクラス
 * @constructor
 */
 class SwitchingImg{
    constructor() {
  
    }
    /**
     * サンプル
     */
    init() {
        const elm1 = document.getElementById("image01");
        const elm2 = document.getElementById("image02");
        const elm3 = document.getElementById("image03");
        const elm4 = document.getElementById("image04");
        $('.js-item1').on('click',function() {
            // elm1.src = "/assets/img/products/allfi_plus_blk.png";
            // elm3.src = "/assets/img/products/allfi_plus_blk.png";
            $('#image01').show();
            $('#image01_2').hide();
            $('.slide_sllfi_blk').show();
            $('.slide_sllfi_wt').hide();

        })
        $('.js-item2').on('click',function() {
            // elm1.src = "/assets/img/products/allfi_plus_wt.png";
            // elm3.src = "/assets/img/products/allfi_plus_wt.png";
            $('#image01').hide();
            $('#image01_2').show();
            $('.slide_sllfi_blk').hide();
            $('.slide_sllfi_wt').show();
        })
        $('.js-item3').on('click',function() {
            // elm2.src = "/assets/img/products/noar_plus_blk.png";
            // elm4.src = "/assets/img/products/noar_plus_blk.png";
            $('#image02').show();
            $('#image02_2').hide();
            $('.slide_noar_blk').show();
            $('.slide_noar_wt').hide();
        })
        $('.js-item4').on('click',function() {
            // elm2.src = "/assets/img/products/noar_plus_wt.png";
            // elm4.src = "/assets/img/products/noar_plus_wt.png";
            $('#image02').hide();
            $('#image02_2').show();
            $('.slide_noar_blk').hide();
            $('.slide_noar_wt').show();
        })
        
    }
  };
  
  export default SwitchingImg;