/**
 * サンプルクラス
 * @constructor
 */
class SearchMenuAnchor {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

      // 以下サポートページ用
      if (window.matchMedia( "(max-width: 768px)" ).matches) {

        let btn = $('.m-searchBtn');

        // ボタンクリックしたら
        btn.on('click', function () {
          // メニューを開くときの動作
          $('.js-wrap').addClass('is-open');
          $('body').addClass('body-fixed');
          $('.js-bg').fadeIn();
        })

        // ×ボタンクリックしたら
        $('.m-anchorSp__closeBtn').on('click', function () {
          $('.js-wrap').removeClass('is-open');
          $('body').removeClass('body-fixed');
          $('.js-bg').fadeOut();
        });
  
        // 背景をクリックしたら閉じる
        $('.js-bg').on('click', function () {
          $(this).fadeOut();
          $('.js-wrap').removeClass('is-open');
          $('body').removeClass('body-fixed');
        });

        // 遷移前にメニュー閉じる
        let open = $('.js-wrap').hasClass('is-open');
        if(open = true){
          $('a[href^="#"]').on('click',function(){
            $('.js-bg').fadeOut();
            $('.js-wrap').removeClass('is-open');
            $('body').removeClass('body-fixed');
          });
        }
      
      }

  }
};

export default SearchMenuAnchor;
