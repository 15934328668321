/**
 * サンプルクラス
 * @constructor
 */
class Active {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    $('.js-parent').hover(function () {
      $(this).find('.js-child').addClass('is-active');
    }, function () {
        $(this).find('.js-child').removeClass('is-active');
    });

    // 取扱説明ページ・SP版ページ内リンクナビゲーション
    $(function() {
      var $NAV_TEXT = $('.p-prodNavi__flex-item-link');
      $($NAV_TEXT).on('click', function() {
        $($NAV_TEXT).removeClass('p-prodNavi__flex-item-active');
        $(this).addClass('p-prodNavi__flex-item-active');
      });
    });

  }
};

export default Active;
