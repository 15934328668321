import Swiper from "swiper";
/**
 * サンプルクラス
 * @constructor
 */
class SwiperSetting {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    // /about/と/enjoyment/のスライド
    let mySwiper01 = new Swiper(".slide01", {
      loop: true, //ループさせる
      loopAdditionalSlides: 1,
      speed:500,
      autoplay: true,
      autoplay: {
        disableOnInteraction: false
        // スライド操作後に自動再生を止めるか
      },
      allowTouchMove: true,//マウスでのスワイプ
      slidesPerView: 2.7,
      centeredSlides : true,
      breakpoints: {
        // ウィンドウサイズが750px以下
        750: {
          slidesPerView: 1.33,
          spaceBetween: 12
        }
      }
    });
    

      //swiper 768以下で起動
      let mySwiper02; 
      $(window).on('load resize', function(){
          let windowWidth = $(window).width();
          if (windowWidth >= 750) {
            if (mySwiper02) {
              return;
            } else {
              mySwiper02 = new Swiper('.slide02', {
                loop: true, //ループさせる
                loopAdditionalSlides: 1,
                speed:700,
                autoplay: true,
                autoplay: {
                  disableOnInteraction: false
                  // スライド操作後に自動再生を止めるか
                },
                allowTouchMove: true,//マウスでのスワイプ
                slidesPerView: 5,
                centeredSlides : true,
                breakpoints: {
                  // ウィンドウサイズが750px以下
                  750: {
                    slidesPerView: 1,
                  },
                  1000:{
                    slidesPerView: 2,
                  },
                  1200:{
                    slidesPerView: 2.5,
                  },
                  1400:{
                    slidesPerView: 3,
                  },
                  1600:{
                    slidesPerView: 3.5,
                  },
                  1900:{
                    slidesPerView: 4,
                  },
                  2100:{
                    slidesPerView: 4.5,
                  },
                }
              });
            }
          } else {
            if (mySwiper02) {
                mySwiper02.destroy();
                mySwiper02 = undefined;
            } 
          } 
      });

  }
};

export default SwiperSetting;
