/**
 * サンプルクラス
 * @constructor
 */
class SearchMenu {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

      if (window.matchMedia( "(max-width: 768px)" ).matches) {

        // ボタンクリックしたら
        $('.m-searchBtn').on('click', function () {
          // メニューを開くときの動作
          $('.p-prodList__search').addClass('is-open');
          $('body').addClass('body-fixed');
          $('.p-prodList__searchBg').fadeIn();
        })

        // ×ボタンクリックしたら
        $('.p-prodList__closeBtn').click(function () {
          $('.p-prodList__search').removeClass('is-open');
          $('body').removeClass('body-fixed');
          $('.p-prodList__searchBg').fadeOut();
          $('.m-linkBoxB__slide').slideUp(1000);
        });
  
        // 背景をクリックしたら閉じる
        $('.p-prodList__searchBg').click(function () {
          $(this).fadeOut();
          $('.p-prodList__search').removeClass('is-open');
          $('body').removeClass('body-fixed');
          $('.m-linkBoxB__slide').slideUp(1000);
        });

      }

  }
};

export default SearchMenu;
