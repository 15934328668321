/**
 * サンプルクラス
 * @constructor
 */
  class Header {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {


  $('.l-headerMenu__item--drop,.l-headerDrop').on({
    'mouseenter': function () {
      $('.l-headerDrop').stop().fadeIn(300);
    },
    'mouseleave': function () {
      $('.l-headerDrop').stop().fadeOut(300);
    }
  });

  $('.m-headerSP__burger').on('click' , function () {
    $('.m-headerSP__burger, .m-headerSP__navwrapper').toggleClass('open');
  });

  $('.m-headerSP__brandbtn').on('click' , function () {
    $(this).next('.m-headerSP__submenu').slideToggle("fast");
    $(this).toggleClass('isActive');
  });

  // メガメニュー・吹き出し・グレー背景：表示切替

  const enter = $('.m-headerPC__link-item_a');
  const outer = $('.m-headerPC__navwrapper');
  const hanteiIsActive = $(this).parent().next().hasClass('isActive');
  
  enter.on({
    'mouseenter' : function () {
      if (hanteiIsActive == true) {
        ;
      } else {
        $('.m-headerPC__submenu').removeClass('isActive');
        $('.m-headerPC__fuki').removeClass('isActive');
        $(this).parent().next().addClass('isActive');
        $(this).next().addClass('isActive');
        $('.m-header__modal').addClass('pc-modal-is-active');
      }
    }
  });

  outer.on({
    'mouseleave' : function () {
      $('.m-headerPC__submenu').removeClass('isActive');
      $('.m-header__modal').removeClass('pc-modal-is-active');
      $('.m-headerPC__fuki').removeClass('isActive');
    }
  });

  }
};

export default Header;
