import Modaal from "modaal";
/**
 * サンプルクラス
 * @constructor
 */

class ModaalSetting {
  constructor() {

  }
  /**
   * サンプル
   */
  init() {

    $(function() {

      $('.slideModalImage').modaal({
        type: 'image',
      });

      $('.videoModal').modaal({
        type: 'video',
      });
      
    });
    
  }
};

export default ModaalSetting;
